<template>
  <div class="list">
    <list-search @onSearch="onSearch" @clearSearch="clearSearch" :childData="childData"></list-search>
    <div class="evaluation-box">
      <div class="exportData">
        <el-button class="textBtn" @click="handlePreview" type="text">导出</el-button>
        <el-button class="textBtn" @click="operationRecord" type="text">操作记录</el-button>
        <el-button class="textBtn" @click="sasa" type="text">部门评价导出</el-button>
        <el-button class="textBtn textBtnRight" @click="back" type="text">返回</el-button>
      </div>
      <el-collapse>
        <el-collapse-item title="基本信息">
          <div class="elrowflx">
            <el-row :gutter="20">
              <el-col class="clearfix" :span="12">
                <div class="xjtit">档案编号</div>
                <div class="xjcont">{{evaluation.number}}</div>
              </el-col>
              <el-col class="clearfix" :span="12">
                <div class="xjtit">档案描述</div>
                <div class="xjcont">{{evaluation.message}}</div>
              </el-col>
              <el-col class="clearfix" :span="12">
                <div class="xjtit">状态</div>
                <div class="xjcont">{{evaluation.status=='1'?"未完成":evaluation.status=='2'?"已发布":evaluation.status=='3'?"已完成":evaluation.status=='4'?"已提交":evaluation.status=='5'?"已计算":""}}</div>
              </el-col>
              <el-col class="clearfix" :span="12">
                <div class="xjtit">考评季度</div>
                <div class="xjcont">{{evaluation.quarter}}</div>
              </el-col>
              <el-col class="clearfix" :span="12">
                <div class="xjtit">考评模板</div>
                <div class="xjcont">{{evaluation.templateName}}</div>
              </el-col>
              <el-col class="clearfix" :span="12">
                <div class="xjtit">建档时间</div>
                <div class="xjcont">{{evaluation.createDate}}</div>
              </el-col>
              <el-col class="clearfix" :span="12">
                <div class="xjtit">考评日期从</div>
                <div class="xjcont">{{evaluation.startDate}}</div>
              </el-col>
              <el-col class="clearfix" :span="12">
                <div class="xjtit">考评日期至</div>
                <div class="xjcont">{{evaluation.endDate}}</div>
              </el-col>
              <el-col class="clearfix" :span="12">
                <div class="xjtit">建档人</div>
                <div class="xjcont">{{evaluation.createUserId}}</div>
              </el-col>
              <el-col class="clearfix" :span="12">
                <div class="xjtit">参评说明</div>
                <div class="xjcont">{{evaluation.explain}}</div>
              </el-col>
            </el-row>
          </div>
        </el-collapse-item>
      </el-collapse>
      <global-table :tableField="tableField" @tableAction="tableAction" :tableData="tableData" :paginationData="paginationData" @pageChange="pageChange"
      ></global-table>
    </div>
    <dialog-box ref="queryDialog" dialogWidth="800px" :dialogShow="dialogShow" :dataBox="dataBox" @handleClose='handleClose' title="操作记录" componentName="OperationBody" :buttonData="[{ label: '关闭', action: 'handleClose', size: 'mini' }]"></dialog-box>

    <dialog-box ref="queryDialog" dialogWidth="1000px" :dialogShow="dialogShowScore" :dataBox="dataBox" @handleClose='handleCloseScore' title="评分明细" componentName="ScoreBody" :buttonData="[{ label: '关闭', action: 'handleClose', size: 'mini' }]"></dialog-box>
  </div>

</template>

<script>
import Component from '@/assets/js/components.js'
import { SearchList1, SearchData1, tableField1, tableData1 } from './js/setting.js'
import { exportForm, request } from '@/assets/js/http.js'
import { MIME } from '@/assets/js/mime.js'
// import dayjs from 'dayjs'
export default {
  components: Component.components,
  name: 'EvaluationDetails',
  data () {
    var pageSize = parseInt((document.documentElement.clientHeight - 330) / 42)
    if (pageSize < 10) {
      pageSize = 10
    }
    return {
      parentId: this.$route.query.id,
      dialogShow: false,
      dialogShowScore: false,
      tableField: tableField1,
      tableData: tableData1,
      dataBox: {
        id: '',
        supperName: ''
      },
      evaluation: {},
      selectTabData: [],
      paginationData: {
        pageSize: pageSize,
        total: 0
      },
      pageNum: 1,
      menudata: this.$store.state.ViewMenuData.menuData,
      childData: { searchList: SearchList1, searchData: SearchData1 }
    }
  },
  created () {
    this.myBidData()
    this.supplierList()
  },

  methods: {
    // 测试的方法
    sasa () {
      var file = { name: '考评数据.xlsx' }
      exportForm(`/api/evaluation/evaluation/poiByPart?id=${this.parentId}&evaluationCode=QUALITY_CRITERIA`, 'POST').then(
        (response) => {
          var fileExtension = file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase()
          var mime = 'application/octet-stream'
          if (MIME[fileExtension]) {
            mime = MIME[fileExtension]
          }
          const aBlob = new Blob([response.data], { type: mime })
          this.downloadByBlob(aBlob, file.name, mime)
        })
    },
    // ID获取考评详情列表
    myBidData () {
      const id = this.parentId
      const obj = {
        supperName: this.childData.searchData.supperName,
        categoryCode: this.childData.searchData.categoryName,
        status: this.childData.searchData.status
      }
      request('/api/evaluation/evaluation/queryOne?pageNum=' + this.pageNum + '&' + 'pageSize=' + this.paginationData.pageSize + '&' + 'id=' + id, 'POST', obj).then((res) => {
        if (res.code === '200') {
          this.evaluation = res.data.evaluation
          this.tableData = res.data.supplierEvaluationIPage.records
          this.paginationData.total = res.data.supplierEvaluationIPage.total
        }
      })
    },

    // 点击操作里的评分明细按钮
    tableAction: function (index, data, clickBtn) {
      if (clickBtn.operationButton === 'see') {
        this.dialogShowScore = true
        console.log('data', data)
        this.dataBox.id = this.parentId
        this.dataBox.supperName = data.supperCode
      }
    },

    // 获取公司数据列表
    supplierList () {
      var newOption = []
      request('/api/supplier/basicData/getForSelect', 'POST').then((res) => {
        if (res.code === '200') {
          res.data.forEach(item => {
            newOption.push({
              label: `${~~item.erpCode}-${item.name}`,
              value: `${~~item.erpCode}`
            })
          })
          this.childData.searchList[0].option = newOption
        }
      })
    },

    // 导出
    handlePreview () {
      const id = this.parentId
      const obj = {
        supperName: this.childData.searchData.supperName,
        categoryCode: this.childData.searchData.categoryName,
        status: this.childData.searchData.status
      }
      var file = { name: '考评数据.xls' }
      exportForm('/api/evaluation/supperEvaluation/poi?id=' + id, 'POST', obj, 'application/json').then(
        (response) => {
          var fileExtension = file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase()
          var mime = 'application/octet-stream'
          if (MIME[fileExtension]) {
            mime = MIME[fileExtension]
          }
          const aBlob = new Blob([response.data], { type: mime })
          this.downloadByBlob(aBlob, file.name, mime)
        })
    },
    downloadByBlob (data, fileName, mime) {
      try {
        const url = window.URL.createObjectURL(data)
        const eleLink = document.createElement('a')
        eleLink.href = url
        eleLink.target = '_blank'
        if (mime.indexOf('image') < 0 && mime.indexOf('pdf') < 0) {
          eleLink.download = fileName
        }
        eleLink.click()
        window.URL.revokeObjectURL(url)
      } catch (error) {
        console.error('download function error!', error)
      }
    },

    // 操作记录弹框
    operationRecord () {
      this.dialogShow = true
      this.dataBox.id = this.parentId
    },

    // 查询
    onSearch (data) {
      this.pageNum = 1
      this.myBidData()
    },
    // 清空
    clearSearch (data) {
      this.pageNum = 1
      this.myBidData()
    },
    // 分页
    pageChange (data) {
      this.pageNum = data
      this.myBidData()
    },
    // 关闭弹框
    handleClose () {
      this.dialogShow = false
    },
    handleCloseScore () {
      this.dialogShowScore = false
    },
    // 返回
    back: () => {
      window.history.back()
    }
  }
}
</script>

<style scoped lang="scss">
.list{
  .exportData{
    border-bottom: 1px solid #dddcdc;
    padding: 8px 0px 5px;
  }
  .textBtn{
    font-size: 16px;
  }
  .textBtnRight{
    float: right;
  }
  .tableList{
    margin-top: 0;
    padding: 0;
  }

  .evaluation-box{
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 30px;
  }
  .elrowflx{
    margin-bottom: 20px;
    :deep(.el-col){
      // margin-bottom: 20px
      line-height: 34px;
    }
  }
  .xjtit{
    float: left;
    margin-right: 20px;
    min-width: 90px;
    text-align: right;
    color: #666;
    font-size: 14px;
  }
  .xjcont{
    float: left;
    width: 50%;
    color: #666;
    font-size: 14px;
    line-height: 20px;
    margin-top: 7px;
    margin-bottom: 5px;
    .el-form-item{
      margin-bottom: 0;
    }
  }
  :deep(.el-collapse-item__header){
    font-size: 16px;
    color: #666;
  }
  :deep(.el-collapse-item__content){
    padding-bottom: 0;
  }
  .el-collapse{
    border-top: 0;
  }
}
</style>
